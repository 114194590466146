import React, { Component, Fragment } from "react";
import { func, array, string, shape, object, number, bool } from "prop-types";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";

import {
  fetchLeads,
  postLeadData,
  fetchLeadAnalytics,
} from "screens/sites/actions";
import { getStartDate, getEndDate } from "screens/sites/helpers/date_helpers";
import { Panel, Table } from "common";
import WhatConvertsModal from "screens/sites/components/WhatConvertsModal";
import LeadAnalytics from "screens/sites/components/LeadAnalytics";
import ThirdPartyFallback from "screens/sites/components/ThirdPartyFallback";

import axios from "axios";
import { useCallRail } from "lib/api";

class WhatConvertsLeads extends Component {
  static propTypes = {
    fetchLeads: func,
    fetchLeadAnalytics: func,
    postLeadData: func,
    formattedLeads: array,
    leadsById: object,
    siteId: number,
    leadAnalytics: object,
    fetchAllSites: bool,
    wc_token: string,
    wc_secret: string,
    page_number: number,
    total_pages: number,
    date: shape({
      month: string,
      year: string,
    }),
    public_hash: string,
    isPublic: bool,
  };

  state = {
    showPrintView: window.location.href.includes("print"),
    isFetching: false,
    isFetchingAnalytics: false,
    showModal: false,
    currentLead: null,
    mp3Html: "",
    currentPage: 0,
    filterLeadsBy: "",
  };

  mounted = true;

  componentDidMount() {
    this.fetchData({
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      page: 1,
      type: "",
      token: this.props.wc_token,
      siteId: this.props.siteId,
      secret: this.props.wc_secret,
      hash: this.props.public_hash,
      mergeChildSites: this.props.fetchAllSites === true ? "1" : "0",
      isPublic: this.props.isPublic,
    });
    this.fetchAnalytics({
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      mergeChildSites: this.props.fetchAllSites === true ? "1" : "0",
      hash: this.props.public_hash,
      isPublic: true,
      compare: this.props.compare,
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = (nextProps) => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentSecret = this.props.wc_secret !== nextProps.wc_secret;
    const hasDifferentCompare = this.props.compare !== nextProps.compare;
    const hasDifferentFetchAllSites =
      this.props.fetchAllSites !== nextProps.fetchAllSites;
    const callRailCompanyIdChanged =
      useCallRail &&
      this.props.callRailCompanyId !== nextProps.callRailCompanyId;

    if (callRailCompanyIdChanged) {
      this.fetchOnCallRailIdChange();
    }
    if (
      hasDifferentMonth ||
      hasDifferentYear ||
      hasDifferentSecret ||
      hasDifferentFetchAllSites
    ) {
      this.setState({ filterLeadsBy: "", currentPage: 0 });
      this.fetchData({
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        page: 1,
        type: "",
        token: this.props.wc_token,
        secret: this.props.wc_secret,
        hash: this.props.public_hash,
        mergeChildSites: this.props.fetchAllSites === true ? "1" : "0",
        isPublic: this.props.isPublic,
      });
      this.fetchAnalytics({
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        hash: this.props.public_hash,
        isPublic: true,
        mergeChildSites: this.props.fetchAllSites === true ? "1" : "0",
        compare: this.props.compare,
      });
    }

    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      this.props.clearCacheBool === true &&
        this.props.fetchLeadAnalytics(
          {
            startDate: getStartDate(this.props.date),
            endDate: getEndDate(this.props.date),
            hash: this.props.public_hash,
            isPublic: true,
            mergeChildSites: this.props.fetchAllSites === true ? "1" : "0",
            compare: this.props.compare,
          },
          () => {},
          this.props.clearCacheBool
        );
    }

    if (hasDifferentCompare || hasDifferentFetchAllSites) {
      this.fetchAnalytics({
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        hash: this.props.public_hash,
        isPublic: true,
        mergeChildSites: this.props.fetchAllSites === true ? "1" : "0",
        compare: this.props.compare,
      });
    }
  };

  fetchOnCallRailIdChange = () => {
    const fetchLeadsParams = {
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      page: 1,
      type: "",
      token: this.props.wc_token,
      siteId: this.props.siteId,
      secret: this.props.wc_secret,
      hash: this.props.public_hash,
      mergeChildSites: this.props.fetchAllSites === true ? "1" : "0",
      isPublic: this.props.isPublic,
    };

    const fetchAnalyticsParams = {
      startDate: getStartDate(this.props.date),
      endDate: getEndDate(this.props.date),
      mergeChildSites: this.props.fetchAllSites === true ? "1" : "0",
      hash: this.props.public_hash,
      isPublic: true,
      compare: this.props.compare,
    };
    this.setState({ isFetching: true, isFetchingAnalytics: true });
    this.props.fetchLeads(
      fetchLeadsParams,
      () => this.mounted && this.setState({ isFetching: false }),
      true,
      this.props.callRailCompanyId
    );
    this.props.fetchLeadAnalytics(
      fetchAnalyticsParams,
      () => this.mounted && this.setState({ isFetchingAnalytics: false }),
      true
    );
  };

  fetchAnalytics = (params) => {
    this.mounted && this.setState({ isFetchingAnalytics: true });
    this.props.fetchLeadAnalytics(
      params,
      () => this.mounted && this.setState({ isFetchingAnalytics: false })
    );
  };

  fetchData = (params) => {
    this.mounted && this.setState({ isFetching: true });
    if (this.props.fetchAllSites === true) {
      return;
    }
    this.props.fetchLeads(
      params,
      () => this.mounted && this.setState({ isFetching: false }),
      false,
      this.props.callRailCompanyId
    );
  };

  handleModalDisplay = async ({ id: { value } }) => {
    this.setState({ showModal: true, currentLead: value });
    const isPhoneCall = this.props.leadsById[value].lead_type === "Phone Call";

    if (!isPhoneCall) return;
    const url = this.props.leadsById[value].play_recording;

    const { data } = await axios({
      method: "GET",
      url,
      auth: {
        username: this.props.wc_token,
        password: this.props.wc_secret,
      },
    });

    this.setState({ mp3Html: data });
  };

  handleModalHide = () => {
    this.setState({ showModal: false, currentLead: null });
  };

  handlePageClick = ({ selected }) => {
    this.setState({ currentPage: selected }, () => {
      const page = selected + 1;
      this.fetchData({
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        page,
        token: this.props.wc_token,
        siteId: this.props.siteId,
        secret: this.props.wc_secret,
        type: this.state.filterLeadsBy,
        hash: this.props.public_hash,
        mergeChildSites: this.props.fetchAllSites === true ? "1" : "0",
        isPublic: this.props.isPublic,
      });
    });
  };

  handleCompareChange = (e, amt) => {
    e.preventDefault();
    this.props.changeComparison(amt);
  };

  handleFilterChange = (e, key) => {
    this.setState({ [key]: e.target.value }, () => {
      this.fetchData({
        startDate: getStartDate(this.props.date),
        endDate: getEndDate(this.props.date),
        page: 1,
        siteId: this.props.siteId,
        token: this.props.wc_token,
        secret: this.props.wc_secret,
        type: this.state.filterLeadsBy,
        hash: this.props.public_hash,
        mergeChildSites: this.props.fetchAllSites === true ? "1" : "0",
        isPublic: this.props.isPublic,
      });
    });
  };

  render() {
    const showInquiriesList = !(this.props.fetchAllSites === true);

    if (
      typeof this.props.wc_secret === "string" ||
      this.props.wc_secret instanceof String
    ) {
      let copy = this.props.wc_secret.trim();
      if (copy.length === 0) {
        return (
          <Fragment>
            <ThirdPartyFallback type="Leads" />
          </Fragment>
        );
      }
    }

    if (this.props.wc_token === null || this.props.wc_secret === null)
      return (
        <Fragment>
          <ThirdPartyFallback type="Leads" />
        </Fragment>
      );
    return (
      <Fragment>
        {this.state.showPrintView && (
          <div className="row">
            <div className="col-xl-12">
              <table className="table">
                <thead>
                  <tr>
                    <th colSpan="6">WhatConverts Lead Site Metrics</th>
                  </tr>
                </thead>
                <tbody>
                  <tr />
                </tbody>
              </table>
            </div>
          </div>
        )}
        <LeadAnalytics
          isPublic={this.props.isPublic}
          handleCompareChange={this.handleCompareChange}
          compare={this.props.compare}
          isFetching={this.state.isFetchingAnalytics}
          leadAnalytics={this.props.leadAnalytics}
        />
        {showInquiriesList && (
          <Fragment>
            <Panel containerClass="col-md-12 p-3">
              <Panel.Header
                title="Inquiries"
                callRailCompanyId={this.props.callRailCompanyId}
                renderLeft={() => {
                  return (
                    <select
                      value={this.state.filterLeadsBy}
                      onChange={(e) =>
                        this.handleFilterChange(e, "filterLeadsBy")
                      }
                      className="form-control"
                    >
                      <option disabled value="default">
                        Select
                      </option>
                      <option value="">All</option>
                      <option value="web_form">Web</option>
                      <option value="phone_call">Phone</option>
                    </select>
                  );
                }}
              />
              <Panel.Body
                isLoading={this.state.isFetching}
                style={{
                  display: "block",
                  borderTop: `2px solid #ffffff`,
                  paddingBottom: "1em",
                }}
                render={() => (
                  <Table
                    fetchMetaData={this.handleModalDisplay}
                    headers={[
                      "Lead Type",
                      "Source",
                      "Phone Name",
                      "Tracking #",
                      "Medium",
                      "Date",
                    ]}
                    data={this.props.formattedLeads}
                  />
                )}
              />
              <Panel.Footer
                render={() => (
                  <div className="row p-4">
                    <ReactPaginate
                      previousLabel="previous"
                      nextLabel={"next"}
                      pageCount={this.props.total_pages}
                      forcePage={this.state.currentPage}
                      disableInitialCallback
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                      breakClassName="page-item"
                      breakLabel={<a className="page-link">...</a>}
                      pageClassName="page-item"
                      previousClassName="page-item"
                      nextClassName="page-item"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                    />
                  </div>
                )}
              />
            </Panel>
            <WhatConvertsModal
              isOpen={this.state.showModal}
              onRequestClose={this.handleModalHide}
              data={this.props.leadsById[this.state.currentLead]}
              leadId={this.state.currentLead}
              mp3Html={this.state.mp3Html}
              postLeadData={this.props.postLeadData}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      permissions: { isPublic },
      data: { wc_token, wc_secret, public_hash, call_rail_company_id },
      leadData: { formattedLeads, leadsById, page_number, total_pages },
      leadAnalytics,
    },
  },
}) => ({
  date,
  formattedLeads,
  leadsById,
  wc_token,
  wc_secret,
  page_number,
  total_pages,
  isPublic,
  public_hash,
  leadAnalytics,
  callRailCompanyId: call_rail_company_id,
});

const mapDispatchToProps = { fetchLeads, postLeadData, fetchLeadAnalytics };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhatConvertsLeads);
