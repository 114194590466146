import React, { Component } from "react";
import { func, number, shape, string, bool, array } from "prop-types";
import { connect } from "react-redux";

import { YearGraph } from "common";
import { fetchGoogleAnalyticsNewVisitors } from "screens/sites/actions";

class GoogleAnalyticsVisitorsByMonth extends Component {
  static propTypes = {
    fetchGoogleAnalyticsNewVisitors: func,
    newVisitors: array,
    siteId: number,
    ga_property_id: string,
    date: shape({
      month: string,
      year: string
    }),
    public_hash: string,
    isPublic: bool,
    displayYOY: bool
  };

  static defaultProps = {
    displayYOY: false
  };

  state = { isFetching: false };
  mounted = true;

  componentDidMount() {
    const params = {
      siteId: this.props.siteId,
      startDate: (this.props.displayYOY ? (this.props.date.year - 1) + "-01-01" : (this.props.date.year) + "-01-01"),
      endDate: (this.props.date.year) + "-12-31",
      hash: this.props.public_hash,
      isPublic: this.props.isPublic,

    };
    this.fetchData(params);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate = nextProps => {
    const hasDifferentMonth = this.props.date.month !== nextProps.date.month;
    const hasDifferentYear = this.props.date.year !== nextProps.date.year;
    const hasDifferentDisplayYOY = this.props.displayYOY !== nextProps.displayYOY;
    const hasDifferentId =
      this.props.ga_property_id !== nextProps.ga_property_id;

    if (!this.props.ga_property_id) return;

    if (hasDifferentMonth || hasDifferentYear || hasDifferentId || hasDifferentDisplayYOY) {
      const params = {
        siteId: this.props.siteId,
        startDate: (this.props.displayYOY ? (this.props.date.year - 1) + "-01-01" : (this.props.date.year) + "-01-01"),
        endDate: (this.props.date.year) + "-12-31",
        hash: this.props.public_hash,
        isPublic: this.props.isPublic
      };
      this.fetchData(params);
    }
    if (this.props.clearCacheBool !== nextProps.clearCacheBool) {
      const params = {
        siteId: this.props.siteId,
        startDate: (this.props.displayYOY ? (this.props.date.year - 1) + "-01-01" : (this.props.date.year) + "-01-01"),
        endDate: (this.props.date.year) + "-12-31",
        hash: this.props.public_hash,
        isPublic: this.props.isPublic
      };
      this.props.clearCacheBool === true && this.props.fetchGoogleAnalyticsNewVisitors(
        params,
        () => { }, this.props.clearCacheBool);
    }
  };

  fetchData = params => {
    if (!params.siteId || !this.props.ga_property_id) return;
    this.mounted && this.setState({ isFetching: true });

    this.props.fetchGoogleAnalyticsNewVisitors(
      params,
      () => this.mounted && this.setState({ isFetching: false })
    );
  };

  render() {
    if (!this.props.ga_property_id) return null;
    return (
      <YearGraph
        className={"yearly-graph"}
        title="Organic New Visitors By Month"
        yLabel="Visitors"
        displayYOY={this.props.displayYOY}
        isLoading={this.state.isFetching}
        data={this.props.newVisitors}
        year={this.props.date.year}
      />
    );
  }
}

const mapStateToProps = ({
  sites: {
    show: {
      date,
      permissions: { isPublic },
      data: { public_hash, id: siteId, ga_property_id },
      analyticsData: { newVisitors }
    }
  }
}) => ({
  date,
  siteId,
  ga_property_id,
  newVisitors,
  isPublic,
  public_hash
});

export default connect(mapStateToProps, { fetchGoogleAnalyticsNewVisitors })(
  GoogleAnalyticsVisitorsByMonth
);
