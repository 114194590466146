import React, { Component } from "react";

import GoogleAnalyticsOrganicSiteMetrics from "screens/sites/components/GoogleAnalyticsOrganicSiteMetrics";
import GoogleAnalyticsVisitorsByMonth from "screens/sites/components/GoogleAnalyticsVisitorsByMonth";
import GoogleAnalyticsSessionsByMonth from "screens/sites/components/GoogleAnalyticsSessionsByMonth";
import GoogleAnalyticsEvents from "screens/sites/components/GoogleAnalyticsEvents";
import GoogleAnalyticsSocialSourcePageViews from "screens/sites/components/GoogleAnalyticsSocialSourcePageViews";
import GoogleAnalyticsVisitorsByDevices from "screens/sites/components/GoogleAnalyticsVisitorsByDevices";
import GoogleAnalyticsVisitorsByLocation from "screens/sites/components/GoogleAnalyticsVisitorsByLocation";
import GoogleAnalyticsTopLandingPages from "screens/sites/components/GoogleAnalyticsTopLandingPages";
import GoogleAnalyticsPageViews from "screens/sites/components/GoogleAnalyticsPageViews";

class PrintAnalytics extends Component {
  render() {
    return (
      <div className="row">
        <div className="print-page page-break" style={{ width: "100%" }}>
          <h1>Google Analytics</h1>
          <GoogleAnalyticsOrganicSiteMetrics />
          <GoogleAnalyticsVisitorsByMonth displayYOY={this.props.displayYOY} />
          <GoogleAnalyticsSessionsByMonth displayYOY={this.props.displayYOY} />
          <GoogleAnalyticsSocialSourcePageViews />
          <GoogleAnalyticsVisitorsByDevices />
        </div>
        <div className="print-page page-break" style={{ width: "100%" }}>
          <GoogleAnalyticsVisitorsByLocation />
          <GoogleAnalyticsTopLandingPages />
          <GoogleAnalyticsPageViews />
          <GoogleAnalyticsEvents />
        </div>
      </div>
    );
  }
}

export default PrintAnalytics;
